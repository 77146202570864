import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Card, Container, Header, Icon, Message, Loader, SemanticCOLORS } from 'semantic-ui-react';

import { getEsSnapshots, EsSnapshotBranch } from './services/kibana-ci-service';
import { reportError } from './lib';

export const EsSnapshots = () => {
  const [snapshots, setSnapshots] = useState<EsSnapshotBranch[] | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const snapshots = await getEsSnapshots();
        setSnapshots(snapshots);
      } catch (error) {
        reportError('Error fetching ES snapshots', error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return (
    <Container style={{ marginTop: '1em' }}>
      <Header>ES Snapshots</Header>

      {isLoading && <Loader active inline="centered" content="Loading" />}
      <Card.Group>
        {snapshots &&
          snapshots.map((s) => {
            const { latestJenkinsBuild } = s;
            const { latestVerified } = s.snapshots;

            const timestampVerified = moment(latestVerified.generated);
            const diff = moment().diff(timestampVerified, 'hours');

            let color: SemanticCOLORS = 'green';
            if (
              latestJenkinsBuild &&
              !latestJenkinsBuild.building &&
              latestJenkinsBuild.result !== 'SUCCESS'
            ) {
              color = 'yellow';
            }
            if (diff >= 24 && (!latestJenkinsBuild || !latestJenkinsBuild.building)) {
              color = 'yellow';
            }
            if (diff >= 26) {
              color = 'yellow';
            }
            if (diff >= 96) {
              color = 'red';
            }

            return (
              <Card color={color} key={s.branch}>
                <Card.Content>
                  <Card.Header>
                    {color !== 'green' && <Icon color={color} name="exclamation circle" />}{' '}
                    {s.branch}
                  </Card.Header>
                  <Card.Meta>{s.version}</Card.Meta>
                </Card.Content>
                {latestVerified && (
                  <>
                    <Card.Content>
                      <Card.Description>
                        <div title={timestampVerified.toString()}>
                          Age: {timestampVerified.fromNow(true)}{' '}
                        </div>
                        <div>
                          Sha:{' '}
                          <a
                            href={`https://github.com/elastic/elasticsearch/commit/${latestVerified.sha}`}
                          >
                            {latestVerified.sha_short}
                          </a>
                        </div>
                        {s.branch !== '6.8' && (
                          <div>
                            <a
                              href={`https://buildkite.com/elastic/kibana-elasticsearch-snapshot-verify/builds/latest?branch=${s.branch}`}
                            >
                              Latest Verification
                            </a>
                          </div>
                        )}
                        {s.branch === '6.8' && latestJenkinsBuild && (
                          <div>
                            <a href={latestJenkinsBuild.url}>Latest Verification</a>:{' '}
                            {latestJenkinsBuild.building ? (
                              <Icon loading name="spinner" />
                            ) : (
                              latestJenkinsBuild.result.toLowerCase()
                            )}{' '}
                          </div>
                        )}
                      </Card.Description>
                    </Card.Content>
                  </>
                )}
              </Card>
            );
          })}
      </Card.Group>

      <Container style={{ marginTop: '3em' }}>
        <Message>
          To use the latest build, even if it failed tests, prefix your commands with:
          <br />
          <Message color="black">
            <pre style={{ overflowX: 'auto' }}>KBN_ES_SNAPSHOT_USE_UNVERIFIED=true</pre>
          </Message>
          For example:
          <Message color="black">
            <pre style={{ overflowX: 'auto' }}>
              KBN_ES_SNAPSHOT_USE_UNVERIFIED=true node scripts/functional_tests_server.js
            </pre>
          </Message>
        </Message>
      </Container>
    </Container>
  );
};
