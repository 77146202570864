import Axios from 'axios';
import { SemanticCOLORS } from 'semantic-ui-react';

import { reportError } from '../lib';

const axios = Axios.create({
  baseURL: (window as any).APP_CONFIG?.BASE_API || 'http://localhost:8080',
});

export interface ApiBuild {
  number: number;
  building: boolean;
  displayName: string;
  description: string;
  timestamp: string;
  url: string;
  result: 'SUCCESS' | 'ERROR' | 'FAILURE' | 'UNSTABLE' | 'ABORTED';
}

export interface Build extends ApiBuild {
  color: SemanticCOLORS;
}

export interface BuildStages {
  tree: BuildStageNode | false;
  allNodes: BuildStageNode[];
}

interface ApiJob {
  _class: string;
  name: string;
  color: string;
  fullDisplayName: string;
  builds?: Build[];
}

export interface Job extends ApiJob {
  running: boolean;
  color: SemanticCOLORS;
  builds: Build[];
}

export interface BuildStageNode {
  id: string;
  console: string;
  durationMillis?: number;
  parent?: BuildStageNode;
  iconColor: SemanticCOLORS;
  running: boolean;
  failed: boolean;
  hasShell: boolean;
  displayName: string;
  children?: BuildStageNode[];
}

export interface EsSnapshot {
  bucket: string;
  branch: string;
  sha: string;
  sha_short: string;
  version: string;
  generated: string;
  archives: Array<{
    filename: string;
    checksum: string;
    url: string;
    version: string;
    platform: string;
    architecture: string;
    license: string;
  }>;
}

export interface EsSnapshotBranch {
  version: string;
  branch: string;
  latestJenkinsBuild: Build;
  snapshots: {
    latest: EsSnapshot;
    latestVerified: EsSnapshot;
  };
}

const getJobColor = (jenkinsColor: string): SemanticCOLORS => {
  const color = jenkinsColor.replace('_anime', '');

  if (color === 'blue' || color === 'red' || color === 'yellow') {
    return color;
  }

  if (color === 'aborted') {
    return 'grey';
  }

  if (color === 'notbuilt') {
    return 'black';
  }

  reportError(`unexpected jenkins job color [${jenkinsColor}]`);
  return 'grey';
};

const getBuildColor = (result: string): SemanticCOLORS => {
  if (result === 'SUCCESS') {
    return 'green';
  }

  if (result === 'ERROR' || result === 'FAILURE') {
    return 'red';
  }

  if (result === 'UNSTABLE') {
    return 'yellow';
  }

  if (result === 'ABORTED' || result == null) {
    return 'grey';
  }

  reportError(`unexpected jenkins build result [${result}]`);
  return 'grey';
};

export const getJobs = async () => {
  const result = await axios.get<ApiJob[]>(`/jobs`);

  return result.data.map(
    (job): Job => ({
      ...job,
      running: job.color.includes('_anime'),
      color: getJobColor(job.color),
      builds: (job.builds || []).map((build) => ({
        ...build,
        color: getBuildColor(build.result),
      })),
    })
  );
};

const getStageColor = (iconColor: string): SemanticCOLORS => {
  iconColor = iconColor.replace('_anime', '');

  if (iconColor === 'blue' || iconColor === 'red' || iconColor === 'yellow') {
    return iconColor;
  }

  if (iconColor === 'aborted') {
    return 'grey';
  }

  reportError(`unexpected build stage iconColor [${iconColor}]`);
  return 'grey';
};

const cleanupNode = (node: BuildStageNode) => {
  node.iconColor = getStageColor(node.iconColor);
  node.hasShell = node.displayName.includes('Shell Script');
  node.failed = node.iconColor !== 'blue';

  for (const child of node.children || []) {
    child.parent = node;
    cleanupNode(child);

    if (child.hasShell) {
      node.hasShell = true;
    }
    if (child.failed) {
      node.failed = true;
    }
  }
};

const flattenTree = (node: BuildStageNode): BuildStageNode[] => {
  if (!node) {
    return [];
  }

  return [
    node,
    ...(node.children || []).reduce(
      (acc: BuildStageNode[], child) => [...acc, ...flattenTree(child)],
      []
    ),
  ];
};

export const getBuildStages = async (
  jobName: string,
  buildNumber: number
): Promise<BuildStages> => {
  const result = await axios.get(`/${jobName}/${buildNumber}`);

  if (!result.data) {
    // if the job was aborted or killed it can have zero stage nodes
    return {
      tree: false,
      allNodes: [],
    };
  }

  cleanupNode(result.data);

  return {
    tree: result.data,
    allNodes: flattenTree(result.data),
  };
};

export const getEsSnapshots = async () => {
  const result = await axios.get<EsSnapshotBranch[]>(`/es-snapshots`);
  return result.data;
};
