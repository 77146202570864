import { toast } from 'react-toastify';

import { history } from './history';

const visibleErrors = new Set();

export function reportError(title: string, error?: Error) {
  const message = error ? `${title}: ${error.message}` : title;

  if (!visibleErrors.has(message)) {
    visibleErrors.add(message);
    toast.error(message, {
      autoClose: false,
      onClose() {
        visibleErrors.delete(message);
      },
    });
  }

  console.error(title, error);
}

export function notFoundError(message: string, replacePath: string) {
  toast.error(message);
  history.replace(replacePath);
}
