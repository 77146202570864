import React from 'react';
import { Router, Switch, Route, Link, Redirect } from 'react-router-dom';
import { Menu, Container } from 'semantic-ui-react';
import { ToastContainer } from 'react-toastify';

import styles from './App.module.css';
import 'react-toastify/dist/ReactToastify.css';

import { history } from './lib';
import { EsSnapshots } from './EsSnapshots';

export default () => (
  <>
    <Router history={history}>
      <Menu inverted attached className={styles.menu}>
        <Container>
          <Menu.Item as={Link} to="/">
            Kibana CI
          </Menu.Item>
          <Menu.Item as={Link} to="/es-snapshots">
            ES Snapshots
          </Menu.Item>
        </Container>
      </Menu>
      <Switch>
        <Route path={['/es-snapshots']}>
          <EsSnapshots />
        </Route>
        <Redirect from="/" to="/es-snapshots" />
      </Switch>
    </Router>
    <ToastContainer
      position="top-right"
      newestOnTop
      closeOnClick
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  </>
);
